import EditIcon from '@app/components/icons/edit';
import ShareIcon from '@app/components/icons/share';
import { useModal } from '@app/components/modal-views/context';
import QRCodePreview from '@app/components/pages/dashboard/QRCodePreview';
import { Button } from '@app/shadcn/components/ui/button';
import { Separator } from '@app/shadcn/components/ui/separator';
import { useToast } from '@app/shadcn/hooks/use-toast';
import html2canvas from 'html2canvas';
import { X } from 'lucide-react';
import { useRouter } from 'next/router';

export default function FormLinkQRCodeModalView({ customizeUrl, formId }: { customizeUrl: string; formId: string }) {
    const { toast } = useToast();

    const { closeModal } = useModal();

    const router = useRouter();

    const handleCopyImageToClipboard = async () => {
        try {
            const canvasElement = document.getElementById('form-qr-code');
            const canvas = canvasElement && (await html2canvas(canvasElement));
            canvas &&
                canvas.toBlob((blob: any) => {
                    if (blob) {
                        const item = new ClipboardItem({ 'image/png': blob });
                        navigator.clipboard.write([item]);
                    }
                }, 'image/png');
            toast({ title: 'QR Image Copied.' });
        } catch (error) {
            toast({ title: 'Error Occured', variant: 'destructive' });
            console.log(error);
        }
    };

    const handleCustomizeLink = async () => {
        closeModal();
        router.push(`/dashboard/${formId}/edit`);
    };

    return (
        <div className="relative flex w-full flex-col items-center gap-6 rounded-xl bg-white p-6 text-center shadow-lighterShadow md:max-w-[420px]">
            <Button className="absolute right-2 top-2 hidden w-fit md:right-4 md:top-4 md:flex" size={'sm'} variant={'secondary-grey'} preIcon={<X />} onClick={closeModal} />
            <div className="flex w-[250px] flex-col gap-3">
                <h1 className="body-18-bold">Share your customized QR Code with your friends</h1>
            </div>
            <QRCodePreview text={customizeUrl} />
            <Separator />
            <div className="flex w-full max-w-[450px] gap-4">
                <Button className="w-full" variant={'secondary-grey'} preIcon={<ShareIcon />} onClick={handleCopyImageToClipboard}>
                    Share
                </Button>
                <Button className="w-full" preIcon={<EditIcon />} onClick={handleCustomizeLink}>
                    Customize QR Code
                </Button>
            </div>
        </div>
    );
}
