import { SVGAttributes } from 'react';

export default function CopyIcon(props: SVGAttributes<{}>) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g clip-path="url(#clip0_8459_5922)">
                <path
                    d="M2.66732 10.6663C1.93398 10.6663 1.33398 10.0663 1.33398 9.33301V2.66634C1.33398 1.93301 1.93398 1.33301 2.66732 1.33301H9.33398C10.0673 1.33301 10.6673 1.93301 10.6673 2.66634M6.66732 5.33301H13.334C14.0704 5.33301 14.6673 5.92996 14.6673 6.66634V13.333C14.6673 14.0694 14.0704 14.6663 13.334 14.6663H6.66732C5.93094 14.6663 5.33398 14.0694 5.33398 13.333V6.66634C5.33398 5.92996 5.93094 5.33301 6.66732 5.33301Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_8459_5922">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
