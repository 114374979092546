import { useModal } from '@app/components/modal-views/context';
import { Button } from '@app/shadcn/components/ui/button';
import { Separator } from '@app/shadcn/components/ui/separator';
import { useLogoutMutation } from '@app/store/users/api';
import { useRouter } from 'next/router';

export default function LogoutModal() {
    const { closeModal } = useModal();
    const router = useRouter();
    const [logout] = useLogoutMutation();
    const handleLogout = async () => {
        await logout().unwrap();
        router.push('/');
    };

    return (
        <div className="flex w-full max-w-[420px] flex-col items-center gap-6 rounded-xl bg-white p-6 lg:w-[420px]">
            <div className="flex flex-col items-center gap-2 text-gray-900">
                <h1 className="text-xl font-semibold">Logout</h1>
                <p className="text-sm text-gray-500">Are you sure you want to logout? </p>
            </div>
            <Separator />
            <div className="flex w-full gap-4">
                <Button onClick={closeModal} className="w-full" variant={'secondary-grey'}>
                    Cancel
                </Button>
                <Button onClick={handleLogout} className="w-full" variant={'destructive'}>
                    Logout
                </Button>
            </div>
        </div>
    );
}
