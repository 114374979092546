import { useModal } from '@app/components/modal-views/context';
import { Button } from '@app/shadcn/components/ui/button';
import { Separator } from '@app/shadcn/components/ui/separator';
import { useToast } from '@app/shadcn/hooks/use-toast';
import { useDeleteFormMutation } from '@app/store/formUrl/api';
import { useRouter } from 'next/router';

export default function DeleteFormModal({ form_id }: { form_id: string }) {
    const { toast } = useToast();

    const { closeModal } = useModal();

    const router = useRouter();

    const [deleteFormLink] = useDeleteFormMutation();

    const handleDeleteClick = async () => {
        await deleteFormLink(form_id);
        closeModal();
        router.push('/dashboard');
        toast({ title: 'Form Deleted successfully' });
    };

    return (
        <div className="flex w-full max-w-[421px] flex-col gap-6 rounded-xl bg-white p-6 text-center">
            <div className="flex flex-col gap-3">
                <h1 className="body-16-bold">Delete Link?</h1>
                <p className="text-sm text-gray-600">Are you sure you want to delete this link? This action cannot be undone.</p>
            </div>
            <Separator />
            <div className="flex w-full max-w-[450px] gap-4">
                <Button className="w-full" variant={'secondary-grey'} onClick={closeModal}>
                    Cancel
                </Button>
                <Button className="w-full" variant={'destructive'} onClick={handleDeleteClick}>
                    Delete
                </Button>
            </div>
        </div>
    );
}
