import { useQRCode } from 'next-qrcode';

function QRCodePreview({ text }: { text: string }) {
    const { Canvas } = useQRCode();

    return (
        <div id={'form-qr-code'}>
            <Canvas
                text={text}
                options={{
                    errorCorrectionLevel: 'M',
                    margin: 3,
                    scale: 4,
                    width: 200,
                    color: {
                        dark: '#0F172A',
                        light: '#F8FAFC'
                    },
                    quality: 1
                }}
            />
        </div>
    );
}

export default QRCodePreview;
