import { LoaderSizeTypes, LoaderVariantTypes } from '@app/components/ui/loader';
import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';

import ButtonLoader from '@app/components/common/button/button-loader';
import cn from 'classnames';

const buttonVariants = cva('inline-flex items-center justify-center cursor-custom text-xs sm:text-sm border gap-2 whitespace-nowrap rounded-xl font-medium transition-colors duration-75 disabled:!cursor-not-allowed', {
    variants: {
        variant: {
            default:
                'bg-primary-gradient active:shadow-primaryBtnActive shadow-new-button focus:shadow-primaryBtnFocus focus:bg-primary-gradient hover:bg-primary-color border-primary text-white active:bg-primary-dark-100 active:bg-none disabled:border-primary-light-500 disabled:bg-primary-light-300 disabled:text-primary-light-900 disabled:bg-none',
            destructive: 'bg-destructive-gradient shadow-new-button focus:bg-destructive-gradient border-red-500 text-white hover:bg-red-600 hover:bg-none active:bg-red-700 active:bg-none disabled:border-red-500 disabled:bg-red-300 disabled:text-red-900',
            outline: 'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
            secondary: 'bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80',
            'secondary-grey':
                'bg-secondary-grey-gradient shadow-secondary-grey-button focus:shadow-secondary-grey-button-focused focus:bg-secondary-grey-gradient active:shadow-secondary-grey-button-active border-white text-grey-900 hover:bg-grey-200 hover:bg-none active:bg-grey-200 active:bg-none',
            ghost: 'hover:bg-accent hover:text-accent-foreground',
            link: 'text-primary underline-offset-4 hover:underline'
        },
        size: {
            xs: 'h-8 px-2 py-[6px]',
            sm: 'h-9 px-[10px] py-2 ',
            md: 'h-10 px-3 py-[10px] ',
            default: 'h-11 px-4 py-3 ',
            xl: 'h-12 px-4 py-[14px]',
            '2xl': 'h-[52px] p-4',
            icon: 'h-9 w-9'
        }
    },
    defaultVariants: {
        variant: 'default',
        size: 'default'
    }
});

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
    isLoading?: boolean;
    loaderSize?: LoaderSizeTypes;
    loaderVariant?: LoaderVariantTypes;
    preIcon?: React.ReactElement;
    postIcon?: React.ReactElement;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ children, className, variant, size, preIcon, postIcon, isLoading = false, loaderSize = 'small', loaderVariant = 'scaleUp', ...props }, ref) => {
    return (
        <button className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props}>
            {preIcon ? (
                <div className="flex items-center gap-1">
                    {preIcon}
                    {children}
                </div>
            ) : postIcon ? (
                <div className="flex items-center gap-1">
                    {children}
                    {postIcon}
                </div>
            ) : (
                children
            )}
            {isLoading && <ButtonLoader size={loaderSize} variant={loaderVariant} />}
        </button>
    );
});
Button.displayName = 'Button';

export { Button, buttonVariants };
