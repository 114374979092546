import { SetStateAction } from 'jotai';
import { createContext, Dispatch, ReactNode, useContext, useState } from 'react';

interface ILogin {
    stage: number;
    isNewUser: boolean;
    email: string;
    redirectFromLandingPage?:boolean
}

interface ILoginContext {
    loginContext: ILogin;
    setLoginContext: Dispatch<SetStateAction<ILogin>>;
}

const LoginContext = createContext<ILoginContext | undefined>(undefined);

export const LoginContextProvider = ({ children }: { children: ReactNode }) => {
    const [loginContext, setLoginContext] = useState<ILogin>({ stage: 0, isNewUser: true, email: '' });
    return <LoginContext.Provider value={{ loginContext, setLoginContext }}>{children}</LoginContext.Provider>;
};

export const useLoginContext = () => {
    const context = useContext(LoginContext);
    if (!context) {
        throw new Error('useLoginContext must be used within LoginContextProvider');
    }
    return context;
};
