import { atom, useAtom } from 'jotai';

export type MODAL_VIEW = 'SUCCESS_MODAL' | 'COPY_GOOGLE_FORM_URL_DEMO' | 'DELETE_MODAL' | 'SHARE_MODAL' | 'LOGOUT_MODAL' | 'QR_MODAL';

const modalAtom = atom({ isOpen: false, view: '', modalProps: null });

export function useModal() {
    const [state, setState] = useAtom(modalAtom);
    const openModal = (view: MODAL_VIEW, modalProps: any = null) =>
        setState({
            ...state,
            isOpen: true,
            view,
            modalProps
        });
    const closeModal = () => {
        setState({ ...state, isOpen: false, modalProps: null, view: '' });
    };

    return {
        ...state,
        openModal,
        closeModal
    };
}
