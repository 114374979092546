import React from 'react';

function EditIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M9.49927 14.9998H16.2493M12.7812 2.7163C13.0798 2.41773 13.4848 2.25 13.907 2.25C14.3292 2.25 14.7342 2.41773 15.0328 2.7163C15.3313 3.01487 15.4991 3.41981 15.4991 3.84205C15.4991 4.26429 15.3313 4.66923 15.0328 4.9678L6.02525 13.9761C5.84682 14.1545 5.62627 14.285 5.384 14.3556L3.23 14.9841C3.16546 15.0029 3.09705 15.004 3.03193 14.9873C2.96681 14.9706 2.90737 14.9368 2.85984 14.8892C2.8123 14.8417 2.77842 14.7822 2.76173 14.7171C2.74505 14.652 2.74618 14.5836 2.765 14.5191L3.3935 12.3651C3.46417 12.1231 3.59469 11.9028 3.773 11.7246L12.7812 2.7163Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default EditIcon;
