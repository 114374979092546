import TextInput from '@app/components/common/input/TextInput';
import SuccessIcon from '@app/components/icons/success-icon';
import { Button } from '@app/shadcn/components/ui/button';
import { Separator } from '@app/shadcn/components/ui/separator';
import { useGetUserStatusQuery } from '@app/store/users/api';
import { useRouter } from 'next/router';
import { useModal } from '../context';
import { useToast } from '@app/shadcn/hooks/use-toast';

export default function SuccessModal({ mappedUrl }: { mappedUrl: string }) {
    const router = useRouter();
    const { toast } = useToast();
    const { closeModal } = useModal();
    const { data: user } = useGetUserStatusQuery();

    const handleCopyCustomizeLink = () => {
        navigator.clipboard.writeText(mappedUrl);
        toast({ title: 'Copied to clipboard' });
    };

    const handleCloseClick = () => {
        if (user?.id) {
            closeModal();
            router.replace('/dashboard');
        }
        closeModal();
    };
    return (
        <div className="flex w-full flex-col items-start gap-4 rounded-xl bg-white-1 p-6 md:w-[420px]">
            <div className="flex w-full justify-center">
                <SuccessIcon />
            </div>
            <p className="body-16-bold text-center">Your customized link has been successfully created!</p>
            <div className="flex w-full flex-col gap-2">
                <p className="body-14-medium">Customized link</p>
                <TextInput value={mappedUrl} readOnly type="text" className="w-full" />
            </div>
            <Separator />
            <div className="flex w-full gap-4">
                <Button variant={'secondary-grey'} className="w-full" onClick={handleCopyCustomizeLink}>
                    Copy
                </Button>
                <Button onClick={handleCloseClick} className="w-full">
                    Close
                </Button>
            </div>
        </div>
    );
}
