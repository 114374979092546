import React from 'react';

function ShareIcon(props: React.SVGAttributes<{}>) {
    return (
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M6.94247 10.1325L12.065 13.1175M12.0575 4.88251L6.94247 7.86751M16.25 3.75C16.25 4.99264 15.2426 6 14 6C12.7574 6 11.75 4.99264 11.75 3.75C11.75 2.50736 12.7574 1.5 14 1.5C15.2426 1.5 16.25 2.50736 16.25 3.75ZM7.25 9C7.25 10.2426 6.24264 11.25 5 11.25C3.75736 11.25 2.75 10.2426 2.75 9C2.75 7.75736 3.75736 6.75 5 6.75C6.24264 6.75 7.25 7.75736 7.25 9ZM16.25 14.25C16.25 15.4926 15.2426 16.5 14 16.5C12.7574 16.5 11.75 15.4926 11.75 14.25C11.75 13.0074 12.7574 12 14 12C15.2426 12 16.25 13.0074 16.25 14.25Z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}

export default ShareIcon;
