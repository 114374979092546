import '@app/assets/css/globals.css';
import ModalContainer from '@app/components/modal-views/container';
import NextNProgress from '@app/components/ui/nprogress';
import createEmotionCache from '@app/configs/createEmotionCache';
import globalConstants from '@app/constants/global';
import { BrowserFingerPrintProvider } from '@app/context/browser-fingerprint';
import { LoginContextProvider } from '@app/context/login-provider';
import { ScrollProvider } from '@app/context/scroll-context';
import { Toaster } from '@app/shadcn/components/ui/toaster';
import { store } from '@app/store/store';
import { NextPageWithLayout } from '@app/types';
import { capitalize } from '@app/utils/stringUtils';
import { CacheProvider, EmotionCache } from '@emotion/react';
import { NextSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { RenderFormProps } from './to/[mappedPath]';

const clientSideEmotionCache = createEmotionCache();
type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
    emotionCache?: EmotionCache;
    pageProps: RenderFormProps | any;
};
export default function App({ Component, pageProps, router, emotionCache = clientSideEmotionCache }: AppPropsWithLayout) {
    const getLayout = Component.getLayout ?? ((page: any) => page);

    let title = globalConstants.title || globalConstants.socialPreview.title;
    let description = globalConstants.socialPreview.desc;
    let url = globalConstants.socialPreview.url;
    let imageUrl = globalConstants.socialPreview.image;

    if (pageProps?.mappedPath) {
        title = capitalize(pageProps.mappedPath);
        imageUrl = '/social-preview-form.png';
        description = 'Fill out this Form by clicking on the custom link.';
        url = url + 'to/' + pageProps?.mappedPath;
    }

    return (
        <CacheProvider value={emotionCache}>
            <NextSeo
                title={title || globalConstants.socialPreview.title}
                description={description}
                openGraph={{
                    type: 'website',
                    locale: 'en_IE',
                    url,
                    site_name: title || globalConstants.appName,
                    description: description,
                    title,
                    images: [
                        {
                            url: imageUrl,
                            alt: title ?? 'formurl'
                        }
                    ]
                }}
                additionalMetaTags={router.pathname === '/' ? [globalConstants.keywords] : []}
                twitter={{
                    handle: globalConstants.twitterHandle,
                    site: url,
                    cardType: 'summary_large_image'
                }}
            />
            <NextNProgress color="#0764EB" startPosition={0} stopDelayMs={400} height={2} options={{ easing: 'ease' }} />
            <Provider store={store}>
                {router.asPath.includes('/to/')
                    ? getLayout(<Component {...pageProps} key={router.asPath} />)
                    : getLayout(
                          <ScrollProvider>
                              <BrowserFingerPrintProvider>
                                  <LoginContextProvider key="login-page-provider">
                                      <Toaster />
                                      <Component {...pageProps} key={router.asPath} />
                                  </LoginContextProvider>
                              </BrowserFingerPrintProvider>
                          </ScrollProvider>
                      )}
                <ModalContainer />
            </Provider>
        </CacheProvider>
    );
}
