import FacebookIcon from '@app/components/icons/share/facebook-icon';
import LinkedInIcon from '@app/components/icons/share/linkedIn-icon';
import MessengerIcon from '@app/components/icons/share/messenger-icon';
import TelegramIcon from '@app/components/icons/share/telegram-icon';
import TwitterIcon from '@app/components/icons/share/twitter-icon';
import WhatsAppIcon from '@app/components/icons/share/whatsApp-icon';
import { Button } from '@app/shadcn/components/ui/button';
import { ReactElement } from 'react';
import { FacebookMessengerShareButton, FacebookShareButton, LinkedinShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

const SocialShareComponent = ({ customizedUrl }: { customizedUrl: string }) => {
    const SocialShareOptions = [
        {
            title: 'Whatsapp',
            component: (
                <WhatsappShareButton url={customizedUrl}>
                    <WhatsAppIcon className="h-6 w-6" />
                </WhatsappShareButton>
            )
        },
        {
            title: 'Messenger',
            component: (
                <FacebookMessengerShareButton url={customizedUrl} appId="ssasa">
                    <MessengerIcon className="h-6 w-6" />
                </FacebookMessengerShareButton>
            )
        },
        {
            title: 'Facebook',
            component: (
                <FacebookShareButton url={customizedUrl}>
                    <FacebookIcon className="h-6 w-6" />
                </FacebookShareButton>
            )
        },
        {
            title: 'LinkedIn',
            component: (
                <LinkedinShareButton url={customizedUrl}>
                    <LinkedInIcon className="h-6 w-6" />
                </LinkedinShareButton>
            )
        },
        {
            title: 'Twitter',
            component: (
                <TwitterShareButton url={customizedUrl}>
                    <TwitterIcon className="h-6 w-6" />
                </TwitterShareButton>
            )
        },
        {
            title: 'Telegram',
            component: (
                <TelegramShareButton url={customizedUrl}>
                    <TelegramIcon className="h-6 w-6" />
                </TelegramShareButton>
            )
        }
    ];

    return (
        <div className="flex w-full flex-row flex-wrap gap-2 text-gray-500 md:gap-4 lg:gap-6">
            {SocialShareOptions.map((socialShare: { title: string; component: ReactElement }, index) => {
                return (
                    <div className="flex min-w-[80px] flex-col items-center gap-2 sm:min-w-0 lg:gap-3" key={index}>
                        <Button variant={'secondary-grey'} size={'2xl'} className="!h-14 !p-4 lg:!h-16 lg:!p-5">
                            {socialShare.component}
                        </Button>
                        <span className="body-14-medium">{socialShare.title}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default SocialShareComponent;
