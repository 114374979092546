import React from 'react';

export default function SuccessIcon(props?: React.SVGAttributes<{}>) {
    return (
        <svg width="73" height="73" viewBox="0 0 73 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <g filter="url(#filter0_i_8199_9874)">
                <path
                    d="M12.0496 26.2216C11.6117 24.2492 11.679 22.1981 12.2451 20.2586C12.8112 18.3191 13.8578 16.554 15.288 15.1268C16.7182 13.6996 18.4855 12.6567 20.4262 12.0946C22.3669 11.5326 24.4181 11.4696 26.3896 11.9116C27.4747 10.2145 28.9696 8.8179 30.7365 7.85048C32.5033 6.88306 34.4852 6.37598 36.4996 6.37598C38.514 6.37598 40.4959 6.88306 42.2628 7.85048C44.0296 8.8179 45.5245 10.2145 46.6096 11.9116C48.5841 11.4677 50.6388 11.5303 52.5826 12.0938C54.5264 12.6572 56.2961 13.703 57.7271 15.1341C59.1582 16.5651 60.2041 18.3348 60.7675 20.2786C61.3309 22.2224 61.3935 24.2771 60.9496 26.2516C62.6467 27.3367 64.0433 28.8316 65.0107 30.5985C65.9782 32.3653 66.4852 34.3473 66.4852 36.3616C66.4852 38.376 65.9782 40.3579 65.0107 42.1248C64.0433 43.8916 62.6467 45.3865 60.9496 46.4716C61.3916 48.4431 61.3287 50.4943 60.7666 52.435C60.2046 54.3757 59.1616 56.143 57.7344 57.5732C56.3073 59.0034 54.5421 60.05 52.6026 60.6161C50.6631 61.1823 48.612 61.2495 46.6396 60.8116C45.5559 62.5152 44.0598 63.9178 42.29 64.8896C40.5201 65.8613 38.5337 66.3708 36.5146 66.3708C34.4955 66.3708 32.5091 65.8613 30.7392 64.8896C28.9694 63.9178 27.4733 62.5152 26.3896 60.8116C24.4181 61.2536 22.3669 61.1907 20.4262 60.6286C18.4855 60.0666 16.7182 59.0236 15.288 57.5965C13.8578 56.1693 12.8112 54.4041 12.2451 52.4646C11.679 50.5251 11.6117 48.474 12.0496 46.5016C10.3395 45.4193 8.93084 43.9221 7.95475 42.1493C6.97865 40.3764 6.4668 38.3854 6.4668 36.3616C6.4668 34.3378 6.97865 32.3468 7.95475 30.574C8.93084 28.8011 10.3395 27.3039 12.0496 26.2216Z"
                    fill="url(#paint0_linear_8199_9874)"
                />
            </g>
            <path
                d="M12.0496 26.2216C11.6117 24.2492 11.679 22.1981 12.2451 20.2586C12.8112 18.3191 13.8578 16.554 15.288 15.1268C16.7182 13.6996 18.4855 12.6567 20.4262 12.0946C22.3669 11.5326 24.4181 11.4696 26.3896 11.9116C27.4747 10.2145 28.9696 8.8179 30.7365 7.85048C32.5033 6.88306 34.4852 6.37598 36.4996 6.37598C38.514 6.37598 40.4959 6.88306 42.2628 7.85048C44.0296 8.8179 45.5245 10.2145 46.6096 11.9116C48.5841 11.4677 50.6388 11.5303 52.5826 12.0938C54.5264 12.6572 56.2961 13.703 57.7271 15.1341C59.1582 16.5651 60.2041 18.3348 60.7675 20.2786C61.3309 22.2224 61.3935 24.2771 60.9496 26.2516C62.6467 27.3367 64.0433 28.8316 65.0107 30.5985C65.9782 32.3653 66.4852 34.3473 66.4852 36.3616C66.4852 38.376 65.9782 40.3579 65.0107 42.1248C64.0433 43.8916 62.6467 45.3865 60.9496 46.4716C61.3916 48.4431 61.3287 50.4943 60.7666 52.435C60.2046 54.3757 59.1616 56.143 57.7344 57.5732C56.3073 59.0034 54.5421 60.05 52.6026 60.6161C50.6631 61.1823 48.612 61.2495 46.6396 60.8116C45.5559 62.5152 44.0598 63.9178 42.29 64.8896C40.5201 65.8613 38.5337 66.3708 36.5146 66.3708C34.4955 66.3708 32.5091 65.8613 30.7392 64.8896C28.9694 63.9178 27.4733 62.5152 26.3896 60.8116C24.4181 61.2536 22.3669 61.1907 20.4262 60.6286C18.4855 60.0666 16.7182 59.0236 15.288 57.5965C13.8578 56.1693 12.8112 54.4041 12.2451 52.4646C11.679 50.5251 11.6117 48.474 12.0496 46.5016C10.3395 45.4193 8.93084 43.9221 7.95475 42.1493C6.97865 40.3764 6.4668 38.3854 6.4668 36.3616C6.4668 34.3378 6.97865 32.3468 7.95475 30.574C8.93084 28.8011 10.3395 27.3039 12.0496 26.2216Z"
                stroke="url(#paint1_linear_8199_9874)"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <g filter="url(#filter1_d_8199_9874)">
                <path d="M27.5 36.3613L33.5 42.3613L45.5 30.3613" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <filter id="filter0_i_8199_9874" x="4.9668" y="4.87598" width="63.0176" height="66.9951" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.206889 0 0 0 0 0.466667 0 0 0 0 0.357955 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_8199_9874" />
                </filter>
                <filter id="filter1_d_8199_9874" x="20.5" y="28.3613" width="32" height="27" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="6" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0.1067 0 0 0 0 0.1143 0 0 0 0 0.12 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8199_9874" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_8199_9874" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_8199_9874" x1="36.476" y1="6.37598" x2="36.476" y2="66.3708" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#06BE71" />
                    <stop offset="1" stop-color="#63DCA9" />
                </linearGradient>
                <linearGradient id="paint1_linear_8199_9874" x1="36.476" y1="6.37598" x2="36.476" y2="66.3708" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#63DCA9" />
                    <stop offset="0.801" stop-color="#63DCA9" />
                </linearGradient>
            </defs>
        </svg>
    );
}
