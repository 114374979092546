const IS_IN_PRODUCTION_MODE = process.env.NEXT_PUBLIC_NODE_ENV === 'production';

const environments = {
    // build-time configs
    IS_IN_PRODUCTION_MODE,
    API_ENDPOINT_HOST: process.env.NEXT_PUBLIC_API_ENDPOINT_HOST || '/api/v1',
    FEEDBACK_FORM: process.env.NEXT_PUBLIC_FEEDBACK_FORM || 'https://forms.bettercollected.com/formurl/forms/feedback-form',
    UMAMI_DATA_WEBSITE_ID: process.env.NEXT_PUBLIC_UMAMI_DATA_WEBSITE_ID
};

export default environments;
