import { cva, VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import * as React from 'react';

const inputVariants = cva('rounded-xl border-2 text-gray-900 outline-none placeholder:text-gray-500', {
    variants: {
        variant: {
            default: 'focus:shadow-input-focus border-gray-200 bg-gray-50 hover:border-primary focus:border-primary disabled:border-gray-200 disabled:text-gray-400',
            error: 'shadow-input-error border-red-500 ',
            warning: 'shadow-input-warning border-orange-500',
            info: 'shadow-input-info border-blue-500',
            success: 'shadow-input-success border-green-500 '
        },
        size: {
            sm: 'h-10 px-3 py-[10px]',
            md: 'h-11 p-3 ',
            default: 'h-12 px-3 py-[14px]',
            xl: 'h-[52px] px-3 py-4'
        }
    },
    defaultVariants: {
        variant: 'default',
        size: 'default'
    }
});

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & VariantProps<typeof inputVariants>;

export default function TextInput({ className, variant, size, type, ...props }: InputProps) {
    return <input type={type} className={cn(inputVariants({ variant, size }), className)} {...props} />;
}
