import { createApi } from '@reduxjs/toolkit/query/react';

import { customBaseQueryWithReauth } from '@app/store/customebasequery';
import { LinkDetails, LinkDetailsResponse, LinkExistRequestBody, SubscribeRequestBody, SubscribeResponse, UpdateLinksArgs } from './types';

export const FORM_URL_PATH = 'formUrl';
const LINK_TAGS = 'LINK_TAGS';
export interface FormLink {
    id: string;
    destinationUrl: string;
    mappedDomain: string;
    mappedPath: string;
    createdBy: string;
    createdAt: string;
    updatedAt: string;
}

export const formUrlApi = createApi({
    reducerPath: FORM_URL_PATH,
    refetchOnReconnect: true,
    refetchOnMountOrArgChange: true,
    tagTypes: [LINK_TAGS],
    keepUnusedDataFor: 0,
    baseQuery: customBaseQueryWithReauth,
    endpoints: (builder) => ({
        getLinks: builder.query<Array<LinkDetailsResponse>, { fingerprint: string; queryTag: string }>({
            query: ({ fingerprint, queryTag }) => ({
                url: `/forms?query=${queryTag}&fingerprint=${fingerprint}`,
                method: 'GET'
            }),
            providesTags: [LINK_TAGS]
        }),
        getLinksForImport: builder.query<Array<LinkDetailsResponse>, { fingerprint: string }>({
            query: ({ fingerprint }) => ({
                url: `/forms?fingerprint=${fingerprint}`,
                method: 'GET'
            }),
            providesTags: [LINK_TAGS]
        }),
        updateImportForms: builder.mutation<void, { form_ids: string[] }>({
            query: (body) => ({
                url: `/forms/import`,
                method: 'PATCH',
                body: body
            }),
            invalidatesTags: [LINK_TAGS]
        }),
        getLinksByFormId: builder.query<LinkDetailsResponse, string>({
            query: (form_id) => ({
                url: `/forms/${form_id}`,
                method: 'GET'
            }),
            providesTags: [LINK_TAGS]
        }),
        updateCustomizeLink: builder.mutation<LinkDetails, UpdateLinksArgs>({
            query: ({ form_id, linkBody }) => ({
                url: `/forms/${form_id}`,
                method: 'PATCH',
                body: linkBody
            }),
            invalidatesTags: [LINK_TAGS]
        }),
        customizeFormLink: builder.mutation<LinkDetails, LinkDetails>({
            query: (linkBody) => ({
                url: `/forms`,
                method: 'POST',
                body: JSON.stringify(linkBody)
            }),
            invalidatesTags: [LINK_TAGS]
        }),
        linkExist: builder.mutation<LinkDetails, LinkExistRequestBody>({
            query: (linkExistBody) => ({
                url: `/forms/exists`,
                method: 'POST',
                body: linkExistBody
            })
        }),
        deleteForm: builder.mutation<void, string>({
            query: (form_id: string) => ({
                url: `/forms/${form_id}`,
                method: 'DELETE'
            }),
            invalidatesTags: [LINK_TAGS]
        }),
        subscribe: builder.mutation<SubscribeResponse, SubscribeRequestBody>({
            query: ({ emailAddress }) => ({
                url: `/subscribe`,
                method: 'POST',
                body: JSON.stringify(emailAddress)
            })
        })
    })
});

export const {
    useGetLinksQuery,
    useGetLinksForImportQuery,
    useUpdateImportFormsMutation,
    useGetLinksByFormIdQuery,
    useLazyGetLinksByFormIdQuery,
    useUpdateCustomizeLinkMutation,
    useCustomizeFormLinkMutation,
    useLinkExistMutation,
    useDeleteFormMutation,
    useSubscribeMutation
} = formUrlApi;
