import { useCopyToClipBoard } from '@app/lib/hooks/use-copy-to-clipboard';
import { useToast } from '@app/shadcn/hooks/use-toast';
import { Check } from 'lucide-react';
import { useEffect, useState } from 'react';
import CopyIcon from '../icons/copy-icon';

export const CopyText = ({ text, toastMsg }: { text: string; toastMsg?: string }) => {
    const [copied, setCopied] = useState(false);

    const { copyToClipboard } = useCopyToClipBoard();

    const { toast } = useToast();

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        }
    }, [copied]);

    const handleOnClickCopyIcon = () => {
        copyToClipboard(text);
        if (toastMsg) {
            toast({ title: toastMsg });
        } else {
            setCopied(true);
        }
    };

    return <>{copied ? <Check className="h-4 w-4 text-green-500" /> : <CopyIcon className="cursor-custom" onClick={handleOnClickCopyIcon} />}</>;
};
