import { Close } from '@app/components/icons/close';
import CopyIcon from '@app/components/icons/copy-icon';
import { useModal } from '@app/components/modal-views/context';
import SocialShareComponent from '@app/components/pages/dashboard/SocialShare';
import { Button } from '@app/shadcn/components/ui/button';
import { Separator } from '@app/shadcn/components/ui/separator';
import { Check } from 'lucide-react';
import { useEffect, useState } from 'react';

export default function ShareModal({ customizeUrl }: { customizeUrl: string }) {
    const { closeModal } = useModal();
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 3000);
        }
    }, [copied]);

    const handleCopyCustomizeLink = () => {
        navigator.clipboard.writeText(customizeUrl);
        setCopied(true);
    };

    return (
        <div className="flex w-full max-w-[650px] flex-col gap-6 rounded-xl bg-white p-6">
            <div className="flex items-center justify-between gap-4">
                <h2 className="body-18-bold">Share your customized link with your friends</h2>
                <div onClick={closeModal} className="hover:cursor-pointer">
                    <Button preIcon={<Close className="h-4 w-4 text-gray-900" />} variant={'secondary-grey'} size={'sm'} />
                </div>
            </div>
            <SocialShareComponent customizedUrl={customizeUrl} />
            <Separator />
            <div className="flex flex-col gap-2">
                <h1 className="body-16-semibold">Copy Link</h1>
                <div className="flex h-14 w-full max-w-full items-center justify-between overflow-hidden rounded-lg border border-gray-200 bg-gray-new px-1 lg:px-3">
                    <p className="sm:1/2 truncate px-2 py-2 text-sm text-gray-700 focus:outline-none sm:px-3 sm:text-base md:w-3/4">{customizeUrl}</p>
                    <button className="body-14-medium flex flex-row items-center gap-1 rounded-[8px] bg-secondary p-2 text-white hover:bg-blue-800 md:w-[94px]" onClick={handleCopyCustomizeLink}>
                        {copied ? <Check className="h-5 w-5" /> : <CopyIcon />}
                        <h1 className="w-full truncate">{copied ? 'Copied' : 'Copy link'}</h1>
                    </button>
                </div>
            </div>
        </div>
    );
}
