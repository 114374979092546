import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface IBrowserFingerPrint {
    browserFingerPrint: string | null;
    setBrowserFingerPrint: Dispatch<SetStateAction<string | null>>;
}

export const BrowerFingerPrintContext = createContext<IBrowserFingerPrint | undefined>(undefined);

export const BrowserFingerPrintProvider = ({ children }: { children: ReactNode }) => {
    const [browserFingerPrint, setBrowserFingerPrint] = useState<string | null>(null);
    return <BrowerFingerPrintContext.Provider value={{ browserFingerPrint, setBrowserFingerPrint }}>{children}</BrowerFingerPrintContext.Provider>;
};

export const useBrowserFingerPrintContext = () => {
    const context = useContext(BrowerFingerPrintContext);
    if (!context) {
        throw new Error('useBrowserFingerPrintContext must be used within a BrowserFingerPrintProvider');
    }
    return context;
};
